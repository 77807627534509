import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Img from './Artboard Copy 3 (2) copy.png'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<div className={'logo'}>
    <img src={Img} alt={'logo'}/>
</div>);

